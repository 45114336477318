:root {
  --toast-success-bg: green;
  --toast-info-bg: rgb(173, 173, 54);
  --toast-warning-bg: lightcoral;
  --toast-error-bg: red;

  --toast-text-color-light: rgb(243, 246, 246);
  --toast-text-color-dark: black;
}

.Toastify__toast--success {
  background-color: var(--toast-success-bg) !important;
  color: var(--toast-text-color-light) !important;
}

.Toastify__toast--info {
  background-color: var(--toast-info-bg) !important;
  color: var(--toast-text-color-dark) !important;
}

.Toastify__toast--warning {
  background-color: var(--toast-warning-bg) !important;
  color: var(--toast-text-color-light) !important;
}

.Toastify__toast--error {
  background-color: var(--toast-error-bg) !important;
  color: var(--toast-text-color-light) !important;
}

.container {
  @apply max-w-screen-md p-4 mx-auto;
}

.bg {
  @apply bg-green-50;
}

.bg2 {
  @apply bg-theme1-tertiary;
}

.bgreen {
  @apply bg-green-900;
}

.title1 {
  @apply mb-4 text-lg font-medium lg:text-5xl text-theme1-text1;
}

.title2 {
  @apply mb-4 text-lg font-medium lg:text-5xl text-theme1-text2;
}

.title3 {
  @apply mb-4 text-lg font-medium text-theme1-text2;
}

.subtitle1 {
  @apply text-base font-semibold text-theme1-text1;
}

.subtitle2 {
  @apply text-base font-semibold text-theme1-text2;
}

.message1 {
  @apply font-sans text-sm lg:text-base text-theme1-text1;
}

.message2 {
  @apply font-sans text-xs text-theme1-text2;
}

.message3 {
  @apply text-xs font-normal text-theme1-text2;
}

.text1 {
  @apply text-lg font-medium text-theme1-text1;
}

.text2 {
  @apply text-lg font-medium text-theme1-text2;
}

.row {
  @apply flex flex-row;
}

.col {
  @apply flex flex-col;
}

.theme-1 {
  @apply bg-theme1-primary text-theme1-text1;
}

.theme-2 {
  @apply bg-theme2-primary text-theme2-secondary;
}

.theme-3 {
  @apply bg-theme3-primary text-theme3-secondary;
}
.theme-4 {
  @apply bg-theme4-primary text-theme4-secondary;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-letter {
  transform-origin: 50% 100%;
  animation: rotate 2s linear infinite;
}
/* 
@keyframes moveRow {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
} */

.Toastify__toast--success {
  animation: slideInLeft 0.3s ease, fadeOut 0.3s ease 2.5s;
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scroll-margin: 0; /* remove this later */
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid green; /* Green color for the top border */
  border-radius: 48%;
  width: 48px; /* Size of the spinner */
  height: 48px; /* Size of the spinner */
  animation: spin 1s linear infinite; /* Spin animation */
  margin-bottom: 20px; /* Space between spinner and text */
}

.loading-text {
  font-size: 18px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans";
  font-weight: bolder;
  color: green;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sidebar {
  width: 60px; /* Fixed width */
  transition: width 0.3s;
}

.sidebar:hover {
  /* No width change on hover */
  width: auto;
}

.nav-item {
  position: relative; /* For tooltip positioning */
}

.tooltip {
  display: none;
  position: absolute;
  left: 100%; /* Position it to the right of the nav item */
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.nav-item:hover .tooltip {
  display: block; /* Show tooltip on hover */
}

.animate-marquee {
  animation: marquee 60s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.pause {
  animation-play-state: paused;
}
